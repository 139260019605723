import moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AppState } from '../../app.service';
import { AuthService } from '../../data/auth/auth.service';
import { NotificationService } from '../../data/notification/notification.service';
import { OrgService } from '../../data/org/org.service';

import { CaseBlockView } from '../../data/case/case.block.view.model';
import { Provider } from '../../data/provider/provider.model';
import { SurgeryBlock, SurgeryBlockAssignment, SurgeryBlockProvider, SurgeryBlockManager, SurgeryVoluntaryBlockRelease, SurgeryBlockUtilization, GetSurgeryBlockAssignmentOnDateResponse } from '../../data/org/org.model';
import { SurgeryBlockCreditMat, SurgeryBlockManualCredit } from '@app/data/org/org.surgery.block.model';
import { SurgeryBlockSubscriber } from '../../data/notification/notification.model';
import { SurgeryCase } from '@app/data/case/surgery.model';
import { SurgeryRoom } from '../../data/room/room.model';

@Component({
  styleUrls: ['./surgery-block-on-date-modal.component.scss'],
  templateUrl: './surgery-block-on-date-modal.component.html',
  providers: [NotificationService, OrgService]
})
export class SurgeryBlockOnDateModalComponent implements OnInit, OnDestroy {
  
  private _orgLid!: string;
  private _routerSubscription?: Subscription;

  blockView!: CaseBlockView;
  
  isHoliday?: boolean;
  isAutoReleased?: boolean;
  canVoluntaryRelease?: boolean;
  utilization?: SurgeryBlockUtilization;
  room?: SurgeryRoom;
  block?: SurgeryBlock;
  assignment?: SurgeryBlockAssignment;
  blockProviders?: Array<SurgeryBlockProvider>;
  managers?: Array<SurgeryBlockManager>;
  subscribers?: Array<SurgeryBlockSubscriber>;
  releases?: Array<SurgeryVoluntaryBlockRelease>;
  manualCredits?: Array<SurgeryBlockManualCredit>;
  assignmentCases?: Map<string, SurgeryCase>;
  assignmentCaseCredits?: Array<SurgeryBlockCreditMat>;
  providers?: Array<Provider>;

  headerClass?: string;
  myProviderLid?: string;
  mySubscription?: SurgeryBlockSubscriber;
  amManager: boolean = false;
  releasePossible: boolean = false;
  hasPartialCredit = false;

  constructor(
    protected _router: Router,
    private _notificationService: NotificationService,
    private _orgService: OrgService,
    private _appState: AppState,
    private _authService: AuthService,
    public bsModalRef: BsModalRef,
  ){}

  public getProviderName(providerLid: string): string {
    const found = this.providers?.find(p => p.providerId === providerLid);
    if (found) {
      return found.fullName;
    }

    return '';
  }

  public getCaseId(c: SurgeryCase) {
    return c.caseId;
  }

  public subscribe() {
    this._notificationService.addSurgeryBlockSubscriber(this._orgLid, this.blockView.blockLid, this.blockView.assignmentLid, this.blockView.date)
      .subscribe(response => {
        this.subscribers?.push(response);
        this.mySubscription = this.subscribers?.find(s => s.providerLid == this.myProviderLid);
      },
      err => {
        console.error(err);
      });

      return false;
  }

  public unsubscribe() {
    this._notificationService.deleteSurgeryBlockSubscriber(this._orgLid, this.blockView.blockLid, this.blockView.assignmentLid, this.blockView.date)
      .subscribe(response => {
        this.subscribers = this.subscribers?.filter(s => s.providerLid !== this.myProviderLid);
        this.mySubscription = undefined;
      },
      err => {
        console.error(err);
      });

      return false;
  }

  public onMinimumAvailabilityChange(newMin: any) {
    if (this.mySubscription) {
      this._notificationService.updateSurgeryBlockSubscriber(this._orgLid, this.blockView.blockLid, this.blockView.assignmentLid, this.blockView.date, this.mySubscription?.subscriptionLid, this.mySubscription.minimumAvailability)
        .subscribe(response => {
          ;
        },
        err => {
          console.error(err);
        });
    }

    return false;
  }

  private _getBlockDetails() {
    this._orgService.getSurgeryBlockAssignmentOnDate(this._orgLid, this.blockView.blockLid, this.blockView.assignmentLid, this.blockView.date)
    .subscribe((response: GetSurgeryBlockAssignmentOnDateResponse) => {
      this.isHoliday = response.isHoliday;
      this.isAutoReleased = response.isAutoReleased;
      this.canVoluntaryRelease = response.canVoluntaryRelease;
      this.utilization = response.utilization;
      this.room = response.room;
      this.block = response.block;
      this.assignment = response.assignment;
      this.blockProviders = response.blockProviders;
      this.managers = response.managers;
      this.subscribers = response.subscribers;
      this.releases = response.releases;
      this.manualCredits = response.manualCredits;
      this.assignmentCases = new Map<string, SurgeryCase>();
      for(let s of response.assignmentCases) {
        this.assignmentCases.set(s.caseLid, s);
      }
      this.assignmentCaseCredits = response.assignmentCaseCredits;
      this.providers = response.providers;

      this.mySubscription = this.subscribers.find(s => s.providerLid == this.myProviderLid);
      this.amManager = this.managers.findIndex(m => m.managerLid === this.myProviderLid) !== -1;
      this.releasePossible = !this.isAutoReleased && (this.releases.length === 0) && moment(this.blockView.date).isAfter(moment());
      this.hasPartialCredit = response.assignmentCaseCredits.findIndex(c => c.creditType == 2) !== -1
      this._updateHeader();
    },
    err => {
      console.error(err);
    });
  }

  ngOnInit(): void {
    this._orgLid = this._appState.get('orgId');

    if (this._routerSubscription) this._routerSubscription.unsubscribe();
    this._routerSubscription = this._router.events.subscribe(e => {
      this.bsModalRef.hide();
    });

    this._authService.getOrg().then(org => {
      if (org && org.provider) {
        this.myProviderLid = org.provider.providerId
      }
    },
    (err) => {
      console.error(err);
    });
    this._getBlockDetails();
  }

  ngOnDestroy() {
    if (this._routerSubscription) this._routerSubscription.unsubscribe();
  }

  private _updateHeader() {
    if (this.releases && this.releases.length > 0) {
      this.headerClass = 'is-voluntary-released'
    } else if (!this.utilization) {
      console.error('Unexpected state encountered.')
      this.headerClass = 'is-score-1'
    } else if (this.isAutoReleased) {
      this.headerClass = 'is-auto-released-' + this.utilization.score;
    } else {
      this.headerClass = 'is-score-' + this.utilization.score;
    }
  }
}