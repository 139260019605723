<div class="loading-container" *ngIf="!assignment">
  <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
</div>
<div class="modal-header {{headerClass}}" *ngIf="assignment">
  <h4 class="modal-title pull-left">{{ 'SHARED.BLOCK.BLOCK' | translate }} {{block?.name}}</h4>
  <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body" *ngIf="assignment">
  <div class="row pb-3">
    <div class="col-sm-8">
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.BLOCK' | translate }}</div>
        <div class="col-sm-7"><a [routerLink]="['/', 'surgery', 'block', block?.blockLid]">{{block?.name}}</a></div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.ROOM' | translate }}</div>
        <div class="col-sm-7">{{room?.name}}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.DATE' | translate }}</div>
        <div class="col-sm-7">{{blockView.date}}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'BLOCK.MINUTES_AVAILABLE' | translate }}</div>
        <div class="col-sm-7">{{ utilization?.blockMinutes | number: '1.0-2' }}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'BLOCK.MINUTES_USED' | translate }}</div>
        <div class="col-sm-7">{{ utilization?.inBlockMinutes | number: '1.0-2' }}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'BLOCK.MINUTES_RELEASED' | translate }}</div>
        <div class="col-sm-7">{{ utilization?.releaseMinutes | number: '1.0-2' }}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'BLOCK.MINUTES_TURNOVER_CREDIT' | translate }}</div>
        <div class="col-sm-7">{{ (utilization ? utilization.inBlockCases * utilization.turnover : 0) | number: '1.0-2' }}</div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.UTILIZATION' | translate }}</div>
        <div class="col-sm-7">{{ utilization ? (utilization.value | number: '1.0-2') + '%' : 'N/A' }}</div>
      </div>
      <hr>
      <div class="row mt-2">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.AUTO_RELEASE_WINDOW' | translate }}</div>
        <div class="col-sm-7">
          <ng-container *ngIf="block?.automaticRelease">
            <ng-container [ngSwitch]="block?.automaticRelease?.type">
              <ng-container *ngSwitchCase="2">{{ 'SHARED.BLOCK.PARAM_BUSINESS_DAYS' | translate:{n: block?.automaticRelease?.value} }}</ng-container>
              <ng-container *ngSwitchDefault>{{ 'SHARED.BLOCK.PARAM_DAYS' | translate:{n: block?.automaticRelease?.value} }}</ng-container>
            </ng-container>
            ({{ 'SHARED.BLOCK.CUTOFF_TIME' | translate:{time: block?.automaticRelease?.getCutoffString()} }})
          </ng-container>
          <ng-container *ngIf="!block?.automaticRelease">{{ 'SHARED.BLOCK.NONE' | translate }}</ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.AUTO_RELEASED' | translate }}</div>
        <div class="col-sm-7">{{ isAutoReleased ? ('YES' | translate) : ('NO' | translate) }}</div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.VOLUNTARY_RELEASE_WINDOW' | translate }}</div>
        <div class="col-sm-7">
          <ng-container *ngIf="block?.voluntaryRelease">
            <ng-container [ngSwitch]="block?.voluntaryRelease?.type">
              <ng-container *ngSwitchCase="2">{{ 'SHARED.BLOCK.PARAM_BUSINESS_DAYS' | translate:{n: block?.voluntaryRelease?.value} }}</ng-container>
              <ng-container *ngSwitchDefault>{{ 'SHARED.BLOCK.PARAM_DAYS' | translate:{n: block?.voluntaryRelease?.value} }}</ng-container>
            </ng-container>
            ({{ 'SHARED.BLOCK.CUTOFF_TIME' | translate:{time: block?.voluntaryRelease?.getCutoffString()} }})
          </ng-container>
          <ng-container *ngIf="!block?.voluntaryRelease">{{ 'SHARED.BLOCK.NONE' | translate }}</ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-5 fw-bold">{{ 'SHARED.BLOCK.VOLUNTARY_RELEASED' | translate }}</div>
        <div class="col-sm-7">
          <span *ngIf="releases && releases.length > 0">{{ 'SHARED.BLOCK.YES_BY' | translate:{name: getProviderName(releases[0].releasedBy)} }}</span>
          <span *ngIf="!(releases && releases.length > 0) && (!amManager || isAutoReleased)">{{ 'NO' | translate }}</span>
          <span *ngIf="!(releases && releases.length > 0) && amManager && !isAutoReleased">
            {{ 'NO' | translate }} 
            <span *ngIf="!canVoluntaryRelease"> - {{ 'SHARED.BLOCK.RELEASE_WINDOW_CLOSED' | translate }}</span>
            <span *ngIf="canVoluntaryRelease">
              <a [routerLink]="['/', 'surgery', 'block', block?.blockLid]" *ngxPermissionsOnly="['SurgeryBlockRelease']">{{ 'SHARED.BLOCK_RELEASE' | translate }}</a>
            </span>
          </span>
        </div>
      </div>
      <hr>
      <div class="row mt-2">
        <div class="col-sm-5 fw-bold">{{ (blockProviders && blockProviders.length > 1) ? ('PROVIDERS' | translate) : ('PROVIDER' | translate) }}</div>
        <div class="col-sm-7">
          <ul class="list-unstyled">
            <li *ngFor="let p of blockProviders; let i = index">
              <a [routerLink]="['/', 'entity', 'provider', p.providerLid]">{{ getProviderName(p.providerLid) }}</a>
            </li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="row mt-2">
        <div class="col-sm-5 fw-bold">{{ 'CREDITED_CASES' | translate }}</div>
        <div class="col-sm-7">
          <ul class="list-unstyled">
            <li *ngFor="let c of assignmentCaseCredits">
              {{ c.caseLid | mapLookup:assignmentCases:getCaseId }} 
              <span class="small text-body-secondary" *ngIf="c.creditType === 2">*</span>
              <span class="small text-body-secondary" *ngIf="c.creditType === 3">manual credit {{ c.creditPercent }}%</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row" *ngIf="hasPartialCredit">
        <div class="col-sm-12">
          {{ 'SHARED.BLOCK.CASES_DONE_IN_ANOTHER_ROOM' | translate }}
        </div>
      </div>
    </div>
    <div class="col-sm-4 bg-light">
      <div class="row fw-bold py-2">
        <div class="col">{{ 'SHARED.BLOCK.NOTIFICATIONS' | translate }}</div>
      </div>
      <div class="row mb-2" *ngxPermissionsOnly="['SurgeryBlockSubscribe']">
        <div class="col" *ngIf="releasePossible">
          <div *ngIf="!mySubscription">
            <div class="form-group">
              <div class="d-grid gap-2">
                <a href="#" class="btn btn-info btn-sm" (click)="subscribe()"><i class="fa fa-volume-up"></i> {{ 'SHARED.BLOCK.SUBSCRIBE' | translate }}</a>
              </div>
              
            </div>
            <div>
              {{ 'SHARED.BLOCK.BE_NOTIFIED_IF_RELEASED' | translate }}
            </div>
          </div>
          <div *ngIf="mySubscription">
            <div class="form-group">
              <div class="d-grid gap-2">
                <a href="#" class="btn btn-info btn-sm" (click)="unsubscribe()"><i class="fa fa-volume-off"></i> {{ 'SHARED.BLOCK.UNSUBSCRIBE' | translate }}</a>
              </div>
            </div>
            <div>
              {{ 'SHARED.BLOCK.WE_WILL_NOTIFY_IF' | translate:{minutes: mySubscription.minimumAvailability} }}
              <form #minimumAvailabilityForm="ngForm">
                <div class="row mb-3">
                  <label for="minimum" class="col-8 col-form-label">{{ 'SHARED.BLOCK.MIN_AVAILIBILITY' | translate }}</label>
                  <div class="col-4">
                    <select class="form-select form-select-sm" id="minimum" name="minimum" [(ngModel)]="mySubscription.minimumAvailability" (ngModelChange)="onMinimumAvailabilityChange($event)">
                      <option value="30">30</option>
                      <option value="60">60</option>
                      <option value="90">90</option>
                      <option value="120">120</option>
                      <option value="150">150</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="!releasePossible">
          {{ 'SHARED.BLOCK.SUBSCRIPTION_NOT_AVAILABLE' | translate }}
        </div>
      </div>
      <div class="row mb-2" *ngxPermissionsExcept="['SurgeryBlockSubscribe']">
        <div class="col" *ngIf="releasePossible">
          {{ 'SHARED.BLOCK.NOT_AUTHORIZED_TO_SUBSCRIBE' | translate }}
        </div>
      </div>
      <hr>
      <div class="row py-2" *ngIf="releasePossible">
        <div class="col">
          {{subscribers?.length}} {{ subscribers?.length == 1 ? ('SHARED.BLOCK.SUBSCRIBER' | translate) : ('SHARED.BLOCK.SUBSCRIBERS' | translate)}}
        </div>
      </div>
    </div>
  </div>
</div>